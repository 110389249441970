import React from "react";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import ReactExport from "react-data-export";
import "react-moment";
import {decimalAdjust} from "../../Global";
import SelectAll from "../../../src/pages/RegistroVentas/images/select-all.png"
import Process from "../../../src/pages/RegistroVentas/images/money.png"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function getDataExportExcel(dataSet) {
    return [
        {
            columns: [
                {
                    title: "Cliente",
                    width: {wpx: 150}
                },
                {
                    title: "Fecha",
                    width: {wpx: 80}
                },
                {
                    title: "Sucursal",
                    width: {wpx: 250}
                },
                {
                    title: "Estado",
                    width: {wpx: 80}
                },
                {
                    title: "Tipo Comprobante",
                    width: {wpx: 150}
                },
                {
                    title: "Serie",
                    width: {wpx: 80}
                },
                {
                    title: "Numero Comprobante",
                    width: {wpx: 140}
                },
                {
                    title: "Moneda",
                    width: {wpx: 80}
                },
                {
                    title: "Total",
                    width: {wpx: 100}
                }
            ],

            data: dataSet.map(row => {
                return [
                    {
                        value: row.RazonSocial,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.FechaEmision,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Sucursal,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Estado,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.TipoComprobante,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Serie,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.NumeroComprobante,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Abreviatura,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: decimalAdjust('round', row.Total || 0.00, -2),
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },

                ];
            })
        }
    ];
}


const ValesUI = props => {
    const dataExport = getDataExportExcel(props.vales);
    if (props.isLoading)
        return (
            <div className="container mt-10 d-flex justify-content-center">
                <div className="spinner-border" role="status">
                </div>
            </div>
        );
    return (
        <>
            <div className="container mt-5">
                
                <div className="d-flex row mb-3 caja-fondo-blanco-m-2 ml-1 mr-1">
                    {/* <div className="mb-3" align="right"> */}
                            {/* <div className="col-sm no-pad">
                            <button className="btn btn-outline-primary btn-verde-mfp" href="#" onClick={e => {
                                e.preventDefault()
                                this.props.history.push("/vales/reportes/sucursales")
                            }}>Reporte por sucursales</button>
                        </div> */}
                            <div className="col-3 no-pad">
                            <a
                                onClick={props.onSelectAllVales}
                                className="">
                                <img className="icon-vale" src={SelectAll} alt="icono vale"/>
                                <p className="icon-name">Seleccionar todos los pendientes</p>
                            </a>
                            </div>

                            <div className="col-3 no-pad">
                            {props.hasEditPerm ? (<a
                                onClick={props.onProcesarVales}
                                className="">
                                <img className="icon-vale" src={Process} alt="icono vale"/>
                                <p className="icon-name">Procesar Vales</p>
                            </a>) : null}
                            </div>

                    <div className="col-3 no-pad">
                        <button
                            className="boton__Rojo icon-vale"
                            onClick={props.pdfViewer}
                        >
                            <i className="fas fa-file-pdf"/>
                            <p className="icon-name">pdf</p>
                        </button>
                    </div>

                    <div className="col-3 no-pad">
                        <ExcelFile
                            element={
                                <button
                                    className="boton__verdeOscuro icon-vale"

                                >
                                    <i className="fas fa-file-excel"/>
                                </button>

                            }
                            filename="ReporteVales"

                        >
                            <ExcelSheet
                                dataSet={dataExport}
                                name="Vales"
                            >
                            </ExcelSheet>

                        </ExcelFile>
                        <p className="icon-name">excel</p>
                    </div>
                    {/* </div> */}
                </div>
                {/* <div className="row mb-3" align="right">
                        <div className="col-sm">

                            <button
                                className="boton__Rojo mr-2"
                                onClick={props.pdfViewer}
                            >
                                <i className="fas fa-file-pdf" />
                            </button>
                            <ExcelFile
                                element={
                                    <button
                                        className="boton__verdeOscuro"

                                    >
                                        <i className="fas fa-file-excel" />
                                    </button>
                                }
                                filename="ReporteVales"
                            >
                                <ExcelSheet
                                    dataSet={dataExport}
                                    name="Vales"
                                >
                                </ExcelSheet>
                            </ExcelFile>
                        </div>
                    </div> */}


                <div className="row mt-5">
                    <div className="col-sm-auto">
                        <label className="mr-2">
                            Total de seleccionados: {decimalAdjust('floor', props.totalSelected, -6)}
                        </label>
                    </div>
                    <div className="col-sm-auto">
                        <label className="mr-2">
                            <strong>Total de
                                pendientes: {decimalAdjust('floor', props.totalPendientes, -6)}</strong>
                        </label>
                    </div>
                    <div className="col-sm-auto">
                        <label className="mr-2">
                            Total de todos los vales: {decimalAdjust('floor', props.total, -6)}
                        </label>
                    </div>
                </div>

                <ReactTable
                    id="Vales"
                    data={props.vales}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value
                    }
                    onFilteredChange={(filters) => {
                        let tmpData = [...props.vales]
                        for (let i = 0; i < filters.length; i++) {
                            if (filters[i].id === "Serie") {
                                let query = String(filters[i].value).split(',').map(x => x.toLowerCase())
                                tmpData = tmpData.filter(x => query.includes(String(x[filters[i].id]).toLowerCase()))
                            } else
                                tmpData = tmpData.filter(x => String(x[filters[i].id]).toLowerCase().includes(String(filters[i].value).toLowerCase()))
                        }
                        props.reCalculateVales(tmpData);
                    }}
                    columns={[
                        {
                            columns: [

                                {
                                    Header: "Opciones",
                                    id: "IdVale",
                                    width: 300,
                                    accessor: d => d.IdVale,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["IdVale"]
                                        }),
                                    Cell: f => {
                                        return (
                                            <div>

                                                {props.hasEditPerm ? (
                                                    f.original.Estado === 'PENDIENTE' && f.original.showCheck ? (
                                                        <div className="btn">
                                                            <input className={"mr-1"} type="checkbox"
                                                                   checked={f.original.isChecked}
                                                                   onChange={(e) => props.onCheckVale(e, f.original.IdVale)}/>
                                                        </div>
                                                    ) : null
                                                ) : null}


                                                <button
                                                    // className="boton__outline-pequeno--c ml-1"
                                                    className="btn-verde-mfp mr-1" style={{fontSize: "1rem"}}
                                                    onClick={props.handleVerVale(f.original.IdVale)}
                                                    title="Ver detalle del Vale"
                                                >
                                                    <i className=""/>Detalle
                                                </button>

                                                <button style={{fontSize: "1rem"}} onClick={(e) => {
                                                    e.preventDefault()
                                                    props.goToPreventa(f.original.IdPreventa)
                                                }} className="btn-verde-mfp mr-1">
                                                    <i className=""/>Duplicar
                                                </button>

                                                {
                                                    f.original.Estado === "PENDIENTE" && props.hasDeletePerm ?
                                                        (<button
                                                            className="btn-anular"
                                                            onClick={() => props.handleAnularVale(f.original)}
                                                            title="Anular vale"
                                                            style={{fontSize: "1rem"}}
                                                        >Anular
                                                        </button>) : null
                                                }
                                            </div>
                                        );
                                    },

                                    filterAll: true
                                },
                                {
                                    Header: "Cliente",
                                    id: "cliente",
                                    accessor: d => d.RazonSocial,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["RazonSocial"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: 'center',
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADO"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Número Documento",
                                    id: "NroTipoDocumento",
                                    accessor: d => d.NroTipoDocumento,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["NroTipoDocumento"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADO"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Fecha",
                                    id: "fecha",
                                    accessor: d => d.FechaEmision,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["FechaEmision"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADO"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Serie",
                                    id: "Serie",
                                    accessor: d => d.Serie,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["Serie"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: 'center',
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADO"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "NumeroComprobante",
                                    id: "NumeroComprobante",
                                    accessor: d => d.NumeroComprobante,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["NumeroComprobante"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            textAlign: 'center',
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADO"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Alias/Placa",
                                    id: "Alias",
                                    accessor: d => d.Alias,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Alias"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: 'center',
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADO"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Total",
                                    id: "Total",
                                    accessor: d => d.Total.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Total"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: 'center',
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADO"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Modalidad Pago",
                                    id: "IdModalidadPago",
                                    accessor: d => d.IdModalidadPago,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["IdModalidadPago"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: 'center',
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADO"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Estado",
                                    id: "Estado",
                                    accessor: d => d.Estado,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Estado"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADO"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Moneda",
                                    id: "Abreviatura",
                                    accessor: d => d.Abreviatura,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Abreviatura"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: 'center',
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADO"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Tipos de pago", id: "tipoPagos",
                                    width: 300,
                                    accessor: d => {
                                        return d._tipoPagos.map(t => {
                                            return (t.tipo + ": S./" + decimalAdjust('floor', t.monto, -2) + "\n")
                                        });
                                    }, filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["tipoPagos"]
                                    }), filterAll: true,
                                    getProps: (state, rowInfo, column) => {

                                        return {
                                            style: {
                                                textAlign: "left",
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }
                            ]
                        }
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    previousText="Anterior"
                    nextText="Siguiente"
                    noDataText="No se encontraron registros"
                    loadingText="Cargando..."
                    ofText="de"
                    rowsText="filas"
                    pageText="Página"
                    pageSizeOptions={[10, 20, 25, 50, 100]}
                />
            </div>
        </>
    );
};

export default ValesUI;
