import React, { useState, useEffect} from 'react'
import ReactTable from "react-table";
import {BotonDeletePequeno} from '../Plantillas/Botones';
import {decimalAdjust} from "../../Global";

function MovimientoAlmacenDetalle(props) {

    const [rows, setRows] = useState([])

    useEffect(() => {
        setRows(props.data.sort((a, b) => (a.IdDetalleMovimientosAlmacen < b.IdDetalleMovimientosAlmacen) ? 1 : -1))
    }, [props.data])

    return (
        <ReactTable
            data={rows}
            disabled={false}
            columns={[
                {
                    Header: "Producto",
                    accessor: "NombreProductoConPresentacion",
                    minWidth: 235
                },
                {
                    Header: "Tipo Transacción",
                    accessor: "tipoTransaccion"
                },
                {
                    Header: "Precio con IGV/EXO/INA",
                    accessor: "Precio",
                    Cell: row => <div style={{textAlign: "right"}}>{row.value}</div>

                },
                {
                    Header: "Flete",
                    accessor: "Flete",
                    Cell: row => <div style={{textAlign: "right"}}>{row.value}</div>

                },
                {
                    Header: "Cantidad",
                    accessor: "Cantidad",
                    Cell: row => <div style={{textAlign: "right"}}>{row.value}</div>
                },
                {
                    Header: "Total",
                    accessor: "Total",
                    Cell: row => <div style={{textAlign: "right"}}>{decimalAdjust('floor', row.value, -2)}</div>
                },
                {
                    Header: "Acciones",
                    accessor: "IdDetalleOC",
                    Cell: (f) => {
                        return (
                            <div align='center'>
                                <BotonDeletePequeno
                                    title="Eliminar"
                                    type='button'
                                    onClick={
                                        () => props.handleEliminarItem(f.original)
                                    }
                                />
                            </div>
                        )
                    },
                },
            ]}

            defaultPageSize={20}
            showPagination={true}
            style={{
                height: "200px",
                width: "100%",
                fontSize: "13px"
            }}
            className="-striped -highlight"
        />
    )

}

export default MovimientoAlmacenDetalle
