import jsPDF from "jspdf";
import {loadLogoByRUC} from "../../helpers/funcionesGlobales";
import {Section, Style} from "../../pages/RegistroVentas/ClasesImpresion";
import {decimalAdjust, TIPO_MOV_CAJA} from "../../Global";
import moment from "moment";
import printBlob from "../../helpers/printBlob";


async function getTotalByComprobante(idTpoPago, idTurno) {
    const ESTADO_VENTA_TODOS = "Aceptado,Pendiente,PROCESADA,Rechazado";
    const ESTADO_VALE_TODOS = "PENDIENTE,ANULADO,PROCESADO";
    const ESTADO_VENTA_ANULADOS = "Anulado,PROCESADA";
    const ESTADO_VALE_ANULADOS = "ANULADO";


    const fetchTotal = async (idTipoPago, idTurnoCaja, estadoVenta, estadoVale) => {
        let queryParams =
            `idsTipoPago=${idTipoPago}&
            idTurnoCaja=${idTurnoCaja}&
            estadoVenta=${estadoVenta}&
            estadoVale=${estadoVale}            
            `.replace(/\s/g, "");
        let response = await fetch(`/api/turnos/total-ventas?${queryParams}`);
        let resJson = await response.json();
        return Number(resJson.total);
    }

    const totalTodos = await fetchTotal(idTpoPago, idTurno, ESTADO_VENTA_TODOS, ESTADO_VALE_TODOS);
    const totalAnulado = await fetchTotal(idTpoPago, idTurno, ESTADO_VENTA_ANULADOS, ESTADO_VALE_ANULADOS);

    return {
        todo: totalTodos,
        anulado: totalAnulado,
        total: (decimalAdjust('floor', totalTodos - totalAnulado, -2))
    }
}

export const ticketProductoPDF = async (product) => {
    const response = await fetch(`/api/puntosVenta/pdf/custom`);
    const pdf = await response.json();
    
    const {
        HojaAltura,
        HojaAncho,
        HojaOrientacion,
        SeccionPrincipalAltura,
        SeccionPrincipalAncho,
        SeccionPrincipalAlineacion,
        SeccionPrincipalEjeY,
        SeccionPrincipalEjeX,
        TextoPrecioTamano,
        TextoPrincipalTamano
    } = pdf
    const mmPageSize = [HojaAltura, HojaAncho];
    const ptPageSize = mmPageSize.map(coord => coord * 2.83465);
    const orientacion = HojaOrientacion == 'vertical' ? 'portrait' : 'landscape'
    var doc = new jsPDF({
        orientation: orientacion,
        unit: "mm",
        format: ptPageSize
    });
    const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    const ProductoStyle = new Style(TextoPrincipalTamano, "bold", 0.5, SeccionPrincipalAlineacion);
    const ProductoSection = new Section(
        doc,
        SeccionPrincipalEjeX,
        SeccionPrincipalEjeY,
        ProductoStyle,
        SeccionPrincipalAncho,
        null,
        1
    );
    ProductoSection.write(["  ", `${product.nombre}`.toUpperCase()]); //mostrar dos string en el mismo write
    ProductoSection.drawBorder(true, true, true, false);
    const PrecioTittleStyle = new Style(TextoPrecioTamano, "normal", 0.5, 'center');
    const PrecioTittleSection = new Section(
        doc,
        SeccionPrincipalEjeX,
        ProductoSection.endY,
        PrecioTittleStyle,
        ProductoSection.width + 2,
        null,
        1,
        2
    );

    PrecioTittleSection.write(`S/ ${isNaN(product.PrecioConIgv) ? 0 : decimalAdjust("round", product.PrecioConIgv, -2)}`);
    PrecioTittleSection.drawBorder(true, false, true, true);
    window.open(doc.output("bloburl"), "_blank");
}

export const registroMovCajaPDF = async (data, isCopy = false) => {
    let infoPdf = await TraerInfoPDF(data.idTurno);
    const mmPageSize = [80, 3276];

    const ptPageSize = mmPageSize.map(coord => coord * 2.83465);
    var doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: ptPageSize
    });

    let nombreArchivo = `${moment(new Date()).format('YYYY_MM_DD-HH_mm')}` + "-" + `${infoPdf[0][0][0].NombreCaja}`;

    const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    
    //const logoP = loadLogoByRUC(infoPdf[0][0][0].Ruc, 'logo', data.extImg);
    //const logo = await logoP;
    //doc.addImage(logo, "JPEG", 9, 5, 60, 18);

    const ComprobanteStyle = new Style(10, "bold", 1.3, 'center');
    const ComprobanteSection = new Section(
        doc,
        3,
        2,
        ComprobanteStyle,
        74,
        null,
        1
    );

    const headers = [isCopy ? "MOVIMIENTO DE CAJA (COPIA)" : "MOVIMIENTO DE CAJA", `${infoPdf[0][0][0].NombreCaja}`.toUpperCase()]

    ComprobanteSection.write(headers); //mostrar dos string en el mismo write
    ComprobanteSection.drawBorder(false, true);

    /** Empresa Info**/

    const EmpresaTittleStyle = new Style(9, "normal", 1);
    const EmpresaTittleSection = new Section(
        doc,
        4,
        ComprobanteSection.endY + 3.2,
        EmpresaTittleStyle,
        ComprobanteSection.width
    );

    EmpresaTittleSection.write(`${infoPdf[0][0][0].Ruc}`);
    EmpresaTittleSection.write(`${infoPdf[0][0][0].RazonSocial}`.toUpperCase());

    const EmpresaDataStyle = new Style(7, "normal", 1);
    const EmpresaDataSection = new Section(
        doc,
        EmpresaTittleSection.x,
        EmpresaTittleSection.endY + 3.2,
        EmpresaDataStyle,
        EmpresaTittleSection.width
    );
    EmpresaDataSection.write(`${infoPdf[0][0][0].Direccion}`.toUpperCase());
    EmpresaDataSection.write(`${infoPdf[0][0][0].Nombre}`.toUpperCase());

    const usuarioStyle = new Style(8, "normal", 1);
    const usuarioSection = new Section(
        doc,
        EmpresaDataSection.x,
        EmpresaDataSection.endY + 4,
        usuarioStyle,
        EmpresaDataSection.width
    );

    usuarioSection.write(`FECHA DE EMISIÓN: ${data.data.FechaHora || moment(new Date()).format('DD/MM/YYYY HH:mm')}`);
    usuarioSection.write(`USUARIO: ${infoPdf[0][0][0].Usuario}`);
    usuarioSection.write(`EMPLEADO: ${infoPdf[0][0][0].nomEmpleado}`);
    usuarioSection.write(` `);


    const TipoMovStyle = new Style(10, "normal", 1, 'left');
    const TipoMovSection = new Section(
        doc,
        3,
        usuarioSection.endY + 2,
        TipoMovStyle,
        pageWidth - 6,
        null,
        1
    );
    TipoMovSection.write(`TIPO DE MOVIMIENTO: ${data.data.TipoMovimiento.toUpperCase()}        ` + `MONTO: S./ ${decimalAdjust('round', data.data.Monto, -2)}`,);
    TipoMovSection.drawBorder(false, true)


    const ObservacionStyle = new Style(10, "normal", 1, 'left');
    const ObservacionSection = new Section(
        doc,
        3,
        TipoMovSection.endY + 2,
        ObservacionStyle,
        pageWidth - 6,
        null,
        1
    );
    ObservacionSection.write(`OBSERVACION: ${data.data.Observacion ? data.data.Observacion.toUpperCase() : 'No hay observaciones.'}`);

    const LineaStyle = new Style(10, "normal", 1, 'left');
    const LineaSection = new Section(
        doc,
        3,
        ObservacionSection.endY + 4,
        LineaStyle,
        pageWidth - 6,
        null,
        1
    );
    LineaSection.write(`________________`,);
    const FirmaStyle = new Style(10, "normal", 1, 'left');
    const FirmaSection = new Section(
        doc,
        3,
        LineaSection.endY + 1,
        FirmaStyle,
        pageWidth - 6,
        null,
        1
    );
    FirmaSection.write(`           firma`,);

    doc.autoPrint();
    const bloburl = doc.output("bloburl");
    printBlob(bloburl)
}


export const handleSavePDF = async (data) => {
    let infoPdf = await TraerInfoPDF(data.idTurno);
    const mmPageSize = [80, 3276];

    const ptPageSize = mmPageSize.map(coord => coord * 2.83465);
    var doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: ptPageSize
    });

    let nombreArchivo = `${moment(new Date()).format('YYYY_MM_DD-HH_mm')}` + "-" + `${infoPdf[0][0][0].NombreCaja}`;

    const confEmpresa = await (async () => {
        try {
            const req = await fetch(`/api/config/emp`);
            const conf = await req.json();

            if (!('ignoreInitialAmount' in conf)) {
                conf['ignoreInitialAmount'] = false;
            };

            return conf;
        } catch (e) {
            console.error(e)
        }
    })()

    const calculatedAmounts = await ((async (data) => {
        const confIgnoresMontoApertura = confEmpresa.ignoreInitialAmount;

        let _amounts = {
            confIgnoresMontoApertura,
            montoFinalSistemaTunki: (await getTotalByComprobante(8, data.idTurno)).total,
            montoFinalFisicoTunki: +data.MontoFinalTunki,
            montoFinalSistemaPlin: (await getTotalByComprobante(9, data.idTurno)).total,
            montoFinalFisicoPlin: +data.MontoFinalPlin,
            montoFinalSistemaYape: (await getTotalByComprobante(10, data.idTurno)).total,
            montoFinalFisicoYape: +data.MontoFinalYape,
            montoFinalSistemaRappi: (await getTotalByComprobante(11, data.idTurno)).total,
            montoFinalFisicoRappi: +data.MontoFinalRappi,
            totalEntregarConApertura: confIgnoresMontoApertura ? +data.MontoFinalEfectivoSistema + +data.MontoInicial : +data.MontoFinalEfectivoSistema,
            totalEntregarSinApertura: confIgnoresMontoApertura ? +data.MontoFinalEfectivoSistema : +data.MontoFinalEfectivoSistema - +data.MontoInicial,
        };

        const { montoFinalSistemaYape, montoFinalFisicoYape, montoFinalSistemaPlin, montoFinalFisicoPlin, montoFinalSistemaTunki, montoFinalFisicoTunki, montoFinalSistemaRappi, montoFinalFisicoRappi } = _amounts
        
        _amounts['totalSistema'] = +data.MontoFinalEfectivoSistema + +data.MontoFinalTarjetaMasterCardSistema +
        +data.MontoFinalTarjetaVisaSistema + +data.MontoFinalTarjetaAmericanSistema +
        +data.MontoFinalOtraTarjetaSistema + +data.MontoFinalCreditoSistema;
        
        let comprobantesAnulados = data.movCajas.filter(m => m.Tipo === TIPO_MOV_CAJA.SALIDA && m.Observacion.includes("Anulación del comprobante"))
        let sumComprobantesAnulados = comprobantesAnulados.reduce((acc, curr) => acc + curr.Monto, 0);

        _amounts['sumComprobantesAnulados'] = sumComprobantesAnulados;
        _amounts['totalSalidas'] = +data.totales.salidas - sumComprobantesAnulados;
        _amounts['totalEntradas'] = +data.totales.entradas;
        _amounts['totalConsiderar'] = +data.MontoInicial + _amounts['totalEntradas'] - _amounts['totalSalidas']
        _amounts['totalRestoEntradaSalida'] = _amounts['totalEntradas'] - _amounts['totalSalidas']

        _amounts['totalVentasEfectivo'] = data.totales.totalEfectOk - data.totales.totalEfectBad;
        _amounts['ventasContado'] = data.totales.totalEfectOk - data.totales.totalEfectBad + data.MontoFinalTarjetaMasterCardSistema +
            data.MontoFinalTarjetaVisaSistema + data.MontoFinalTarjetaAmericanSistema +
            data.MontoFinalOtraTarjetaSistema + montoFinalSistemaYape + montoFinalSistemaPlin + montoFinalSistemaTunki + montoFinalSistemaRappi;
        
        _amounts['totalVentas'] = data.MontoFinalCreditoSistema + _amounts['ventasContado'];

        _amounts['totalEfectivoSistema'] = +data.MontoFinalEfectivoSistema;
        _amounts['totalEfectivoFisico'] = +data.MontoFinalEfectivoFisico;

        _amounts['totalPagosDigitalesSistema'] = montoFinalSistemaYape + montoFinalSistemaPlin + montoFinalSistemaTunki + montoFinalSistemaRappi;
        _amounts['totalPagosDigitalesFisico'] = montoFinalFisicoYape + montoFinalFisicoPlin + montoFinalFisicoTunki + montoFinalFisicoRappi;
        
        _amounts['totalTarjetasSistema'] = +data.MontoFinalTarjetaMasterCardSistema + +data.MontoFinalTarjetaVisaSistema + +data.MontoFinalTarjetaAmericanSistema + +data.MontoFinalOtraTarjetaSistema;
        _amounts['totalTarjetasFisico'] = +data.MontoFinalTarjetaMasterCardFisico + +data.MontoFinalTarjetaVisaFisico + +data.MontoFinalTarjetaAmericanFisico + +data.MontoFinalOtraTarjetaFisico;

        _amounts['sumatoriaMontosSistema'] = +data.MontoFinalEfectivoSistema + _amounts['totalTarjetasSistema'] + _amounts['totalPagosDigitalesSistema'];
        _amounts['sumatoriaMontosFisico'] = +data.MontoFinalEfectivoFisico + _amounts['totalTarjetasFisico'] + _amounts['totalPagosDigitalesFisico'];

        _amounts['totalCreditoSistema'] = data.MontoFinalCreditoSistema;
        _amounts['totalCreditoFisico'] = data.MontoFinalCreditoSistema;

        _amounts['totalGeneralSistema'] = (confIgnoresMontoApertura ? 0 : +data.MontoInicial) + _amounts['totalVentasEfectivo'] + _amounts['totalPagosDigitalesSistema'] + _amounts['totalTarjetasSistema'] + _amounts['totalRestoEntradaSalida'];

        _amounts['totalMovimientosEfectivoSistema'] = +data.MontoFinalEfectivoSistema + +data.MontoInicial+ + _amounts['totalRestoEntradaSalida'];
        _amounts['totalMovimientosEfectivoFisico'] = +data.MontoFinalEfectivoFisico + +data.MontoInicial+ + _amounts['totalRestoEntradaSalida'];

        _amounts['montoFinalFisicoTotal'] = +_amounts['totalMovimientosEfectivoFisico'] + +data.MontoFinalTarjetaMasterCardFisico + 
            +data.MontoFinalTarjetaVisaFisico + +data.MontoFinalTarjetaAmericanFisico + data.MontoFinalCreditoSistema + 
            +data.MontoFinalOtraTarjetaFisico + +data.MontoFinalFpay + montoFinalFisicoYape + montoFinalFisicoPlin + montoFinalFisicoTunki + montoFinalFisicoRappi;

        _amounts['montoFinalSistemaTotal'] = +_amounts['totalMovimientosEfectivoSistema'] + +data.MontoFinalTarjetaMasterCardSistema + 
            +data.MontoFinalTarjetaVisaSistema + +data.MontoFinalTarjetaAmericanSistema + +data.MontoFinalOtraTarjetaSistema + 
            +data.MontoFinalCreditoSistema + montoFinalSistemaYape + montoFinalSistemaPlin + montoFinalSistemaTunki + montoFinalSistemaRappi;

        return _amounts;
    })(data));

    let simbolo = infoPdf[0][0][0].Simbolo;

    const writeMontos = (section, simbolo, fisico, sistema, spaces = 18) => {
        const _mtoFisico = `${simbolo + '. ' + (decimalAdjust('floor', +fisico, -2)).toFixed(2)}`;
        const _mtoSistema = `${simbolo + '. ' + (decimalAdjust('floor', +sistema, -2)).toFixed(2)}`;
        
        const _mtos = `${_mtoFisico.padStart(spaces, " ") + _mtoSistema.padStart(spaces, " ")}`;

        section.write(_mtos)
    }

    const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    const ComprobanteStyle = new Style(10, "bold", 1.3, 'center');
    const ComprobanteSection = new Section(
        doc,
        3,
        5,
        ComprobanteStyle,
        74,
        null,
        1
    );

    ComprobanteSection.write(["CIERRE DE CAJA".toUpperCase(), `${infoPdf[0][0][0].NombreCaja}`.toUpperCase()]); //mostrar dos string en el mismo write
    ComprobanteSection.drawBorder(false, true);

    /** Empresa Info**/

    const EmpresaTittleStyle = new Style(9, "normal", 1);
    const EmpresaTittleSection = new Section(
        doc,
        4,
        ComprobanteSection.endY + 3.2,
        EmpresaTittleStyle,
        ComprobanteSection.width
    );

    EmpresaTittleSection.write(`${infoPdf[0][0][0].Ruc}`);
    EmpresaTittleSection.write(`${infoPdf[0][0][0].RazonSocial}`.toUpperCase());

    const EmpresaDataStyle = new Style(7, "normal", 1);
    const EmpresaDataSection = new Section(
        doc,
        EmpresaTittleSection.x,
        EmpresaTittleSection.endY + 3.2,
        EmpresaDataStyle,
        EmpresaTittleSection.width
    );
    EmpresaDataSection.write(`${infoPdf[0][0][0].Direccion}`.toUpperCase());
    EmpresaDataSection.write(`${infoPdf[0][0][0].Nombre}`.toUpperCase());

    const usuarioStyle = new Style(8, "normal", 1);
    const usuarioSection = new Section(
        doc,
        EmpresaDataSection.x,
        EmpresaDataSection.endY + 4,
        usuarioStyle,
        EmpresaDataSection.width
    );

    usuarioSection.write(`FECHA DE EMISIÓN: ${data.FechaFin || moment(new Date()).format('DD/MM/YYYY HH:mm')}`);
    usuarioSection.write(`USUARIO: ${infoPdf[0][0][0].Usuario}`);
    usuarioSection.write(`EMPLEADO: ${infoPdf[0][0][0].nomEmpleado}`);

    const tipoVentaStyle = new Style(10, "bold", 1.3, 'center');

    const tipoVentaSection = new Section(
        doc,
        3,
        usuarioSection.endY + 2,
        tipoVentaStyle,
        pageWidth - 6,
        null,
        1
    );

    tipoVentaSection.write(`      TIPO VENTA                          MONTO`);
    tipoVentaSection.drawBorder(false, true);

    const tipoVentaNumberStyle = new Style(9, "normal", 1, 'right');
    const tipoVentaNumberSection = new Section(
        doc,
        4,
        tipoVentaSection.endY + 1,
        tipoVentaNumberStyle,
        tipoVentaSection.width
    );

    const tipoVentaTextoStyle = new Style(9, "normal", 1, 'left');
    const tipoVentaTextoSection = new Section(
        doc,
        4,
        tipoVentaSection.endY + 1,
        tipoVentaTextoStyle,
        tipoVentaSection.width
    );

    tipoVentaTextoSection.write(`${calculatedAmounts.ventasContado !== 0 ? "VENTAS CONTADO" : ''}`);
    tipoVentaNumberSection.write(`${calculatedAmounts.ventasContado !== 0 ? infoPdf[0][0][0].Simbolo + '. ' + (decimalAdjust('floor', calculatedAmounts.ventasContado, -2).toFixed(2)) : ''}`);

    tipoVentaTextoSection.write(`${data.MontoFinalCreditoSistema !== 0 ? "VENTAS CRÉDITO" : ''}`);
    tipoVentaNumberSection.write(`${data.MontoFinalCreditoSistema !== 0 ? infoPdf[0][0][0].Simbolo + '. ' + (decimalAdjust('floor', data.MontoFinalCreditoSistema, -2).toFixed(2)) : ''}`);

    const totalTipoVentaTextoStyle = new Style(9, "bold", 1, 'left');
    const totalTipoVentaTextoSection = new Section(
        doc,
        4,
        tipoVentaTextoSection.endY + 1,
        totalTipoVentaTextoStyle,
        tipoVentaSection.width
    );

    const totalTipoVentaNumberStyle = new Style(9, "bold", 1, 'right');
    const totalTipoVentaNumberSection = new Section(
        doc,
        4,
        tipoVentaNumberSection.endY + 1,
        totalTipoVentaNumberStyle,
        tipoVentaSection.width
    );

    totalTipoVentaTextoSection.write(`${calculatedAmounts.totalVentas !== 0 ? "TOTAL VENTAS" : ''}`);
    totalTipoVentaNumberSection.write(`${calculatedAmounts.totalVentas !== 0 ? infoPdf[0][0][0].Simbolo + '. ' + (decimalAdjust('floor', calculatedAmounts.totalVentas, -2).toFixed(2)) : ''}`);

    const TotalStyle = new Style(8, "normal", 1);
    const TotalSection = new Section(
        doc,
        4,
        totalTipoVentaNumberSection.endY + 2,
        TotalStyle,
        tipoVentaSection.width
    );

    const letrasTotal = await numeroALetras(decimalAdjust('floor', calculatedAmounts.totalVentas, -2))
    TotalSection.write(`${calculatedAmounts.totalVentas !== 0 ? "SON " + letrasTotal : ''}`);

    const totalEntregarStyle = new Style(10, "bold", 1.3, "center");
    const totalEntregarSection = new Section(
        doc,
        TotalSection.x,
        TotalSection.endY + 2,
        totalEntregarStyle,
        TotalSection.width,
        null,
        1
    );
    
    totalEntregarSection.write(`TOTAL A ENTREGAR EN EFECTIVO`);
    totalEntregarSection.drawBorder(false, true);

    const totalEntregarNumberStyle = new Style(9, "normal", 1, 'right');
    const totalEntregarNumberSection = new Section(
        doc,
        4,
        totalEntregarSection.endY + 1,
        totalEntregarNumberStyle,
        totalEntregarSection.width
    );

    const totalEntregarTextoStyle = new Style(9, "bold", 1, 'left');
    const totalEntregarTextoSection = new Section(
        doc,
        4,
        totalEntregarSection.endY + 2,
        totalEntregarTextoStyle,
        totalEntregarSection.width
    );

    if (confEmpresa.ignoreInitialAmount) {
        totalEntregarTextoSection.write("T. SIN MONTO DE APERTURA");
        totalEntregarNumberSection.write(`${infoPdf[0][0][0].Simbolo + '. ' + (decimalAdjust('floor', calculatedAmounts.totalEntregarSinApertura, -2).toFixed(2))}`);
    } else {
        totalEntregarTextoSection.write("T. CON MONTO DE APERTURA");
        totalEntregarNumberSection.write(`${infoPdf[0][0][0].Simbolo + '. ' + (decimalAdjust('floor', calculatedAmounts.totalEntregarConApertura, -2).toFixed(2))}`);
    }


    const MovimientoCajaConEfectivoStyle = new Style(9, "bold", 1.3, 'center');
    const MovimientoCajaConEfectivoSection = new Section(
        doc,
        3,
        totalEntregarNumberSection.endY + 2.5,
        MovimientoCajaConEfectivoStyle,
        pageWidth - 6,
        null,
        1,
    );

    MovimientoCajaConEfectivoSection.write(`MOVIMIENTOS DE CAJA CON EFECTIVO`);
    MovimientoCajaConEfectivoSection.drawBorder(false, true);

    const MovimientoCajaEfectivoTextoStyle = new Style(9, "normal", 1, "left");
    const MovimientoCajaEfectivoTextoSection = new Section(
        doc,
        4,
        MovimientoCajaConEfectivoSection.endY + 2,
        MovimientoCajaEfectivoTextoStyle,
        MovimientoCajaConEfectivoSection.width
    );

    const MovimientoCajaEfectivoNumberStyle = new Style(9, "normal", 1, "right");
    const MovimientoCajaEfectivoNumberSection = new Section(
        doc,
        4,
        MovimientoCajaConEfectivoSection.endY + 2,
        MovimientoCajaEfectivoNumberStyle,
        MovimientoCajaConEfectivoSection.width
    );

    if (!confEmpresa.ignoreInitialAmount) {
        MovimientoCajaEfectivoTextoSection.write(`${data.MontoInicial !== 0 ? "MONTO APERTURA" : ''}`);

        if (data.MontoInicial !== 0) {
            MovimientoCajaEfectivoNumberSection.write(`${simbolo + '. ' + (decimalAdjust('floor', +data.MontoInicial, -2)).toFixed(2)}`);
        }
    }

    MovimientoCajaEfectivoTextoSection.write(`${calculatedAmounts.totalEntradas !== 0 ? "TOTAL ENTRADA" : ''}`);

    if (calculatedAmounts.totalEntradas !== 0) {
        MovimientoCajaEfectivoNumberSection.write(`${simbolo + '. ' + (decimalAdjust('floor', +calculatedAmounts.totalEntradas, -2)).toFixed(2)}`);
    }

    MovimientoCajaEfectivoTextoSection.write(`${calculatedAmounts.totalSalidas !== 0 ? "TOTAL SALIDA" : ''}`);

    if (calculatedAmounts.totalSalidas !== 0) {
        MovimientoCajaEfectivoNumberSection.write(`${simbolo + '. ' + (decimalAdjust('floor', +calculatedAmounts.totalSalidas, -2)).toFixed(2)}`);
    }

    MovimientoCajaEfectivoTextoSection.write(`${calculatedAmounts.totalRestoEntradaSalida !== 0 ? "R. ENTRADA Y SALIDA" : ''}`);

    if (calculatedAmounts.totalRestoEntradaSalida !== 0) {
        MovimientoCajaEfectivoNumberSection.write(`${simbolo + '. ' + (decimalAdjust('floor', +calculatedAmounts.totalRestoEntradaSalida, -2)).toFixed(2)}`);
    }

    MovimientoCajaEfectivoTextoSection.write(`${calculatedAmounts.totalVentasEfectivo !== 0 ? "VENTAS EFECTIVO" : ''}`);

    if (calculatedAmounts.totalVentasEfectivo !== 0) {
        MovimientoCajaEfectivoNumberSection.write(`${simbolo + '. ' + (decimalAdjust('floor', +calculatedAmounts.totalVentasEfectivo, -2)).toFixed(2)}`);
    }

    const TotalMovimientosEfectivoTextoStyle = new Style(9, "bold", 1, "left");
    const TotalMovimientosEfectivooTextoSection = new Section(
        doc,
        4,
        MovimientoCajaEfectivoTextoSection.endY + 1,
        TotalMovimientosEfectivoTextoStyle,
        MovimientoCajaConEfectivoSection.width
    );

    const TotalMovimientosEfectivoNumberStyle = new Style(9, "bold", 1, "right");
    const TotalMovimientosEfectivoNumberSection = new Section(
        doc,
        4,
        MovimientoCajaEfectivoTextoSection.endY + 1,
        TotalMovimientosEfectivoNumberStyle,
        MovimientoCajaConEfectivoSection.width
    );

    TotalMovimientosEfectivooTextoSection.write(`${data.MontoFinalEfectivoSistema !== 0 ? "MOVIMIENTOS DE EFECTIVO" : ''}`);

    if (data.MontoFinalEfectivoSistema !== 0) {
        TotalMovimientosEfectivoNumberSection.write(`${simbolo + '. ' + (decimalAdjust('floor', +data.MontoFinalEfectivoSistema, -2)).toFixed(2)}`);
    }

    // descripción detallada

    const DescripcionDetalladaStyle = new Style(9, "bold", 1.3, 'center');
    const DescripcionDetalladaSection = new Section(
        doc,
        3,
        TotalMovimientosEfectivoNumberSection.endY + 2,
        DescripcionDetalladaStyle,
        pageWidth - 6,
        null,
        1,
    );

    DescripcionDetalladaSection.write(`DESCRIPCIÓN DETALLADA`);
    DescripcionDetalladaSection.drawBorder(false, true, false, false);

    const MontosStyle = new Style(9, "bold", 1, 'center');
    const MontosSection = new Section(
        doc,
        3,
        DescripcionDetalladaSection.endY,
        MontosStyle,
        pageWidth - 6,
        null,
        1
    );

    MontosSection.write(`MÉTODO DE PAGO         FISICO        SISTEMA`);
    MontosSection.drawBorder(false, true);

    const TipoMontoNumberStyle = new Style(9, "normal", 1, 'right');
    const TipoMontoNumberSection = new Section(
        doc,
        4,
        MontosSection.endY + 1,
        TipoMontoNumberStyle,
        MontosSection.width
    );

    const TipoMontoTextoStyle = new Style(9, "normal", 1, 'left');
    const TipoMontoTextoSection = new Section(
        doc,
        4,
        MontosSection.endY + 1,
        TipoMontoTextoStyle,
        MontosSection.width
    );

    TipoMontoTextoSection.write(`${data.MontoFinalEfectivoSistema !== 0 ? "MOV. EFECTIVO".padEnd(27, "") : ''}`);

    if (data.MontoFinalEfectivoSistema !== 0) {
        writeMontos(TipoMontoNumberSection, simbolo, data.MontoFinalEfectivoFisico, data.MontoFinalEfectivoSistema)
    }

    TipoMontoTextoSection.write(`${calculatedAmounts.montoFinalSistemaYape !== 0 ? "YAPE".padEnd(27, "")  : ''}`);

    if (calculatedAmounts.montoFinalSistemaYape !== 0) {
        writeMontos(TipoMontoNumberSection, simbolo, calculatedAmounts.montoFinalFisicoYape, calculatedAmounts.montoFinalSistemaYape);
    }

    TipoMontoTextoSection.write(`${calculatedAmounts.montoFinalSistemaPlin !== 0 ? "PLIN".padEnd(27, "")  : ''}`);
    
    if (calculatedAmounts.montoFinalSistemaPlin !== 0) {
        writeMontos(TipoMontoNumberSection, simbolo, calculatedAmounts.montoFinalFisicoPlin, calculatedAmounts.montoFinalSistemaPlin);
    }

    TipoMontoTextoSection.write(`${calculatedAmounts.montoFinalSistemaTunki !== 0 ? "TUNKI".padEnd(27, "")  : ''}`);
    
    if (calculatedAmounts.montoFinalSistemaTunki !== 0) {
        writeMontos(TipoMontoNumberSection, simbolo, calculatedAmounts.montoFinalFisicoTunki, calculatedAmounts.montoFinalSistemaTunki);
    }

    TipoMontoTextoSection.write(`${calculatedAmounts.montoFinalSistemaRappi !== 0 ? "RAPPI".padEnd(27, "")  : ''}`);
    
    if (calculatedAmounts.montoFinalSistemaRappi !== 0) {
        writeMontos(TipoMontoNumberSection, simbolo, calculatedAmounts.montoFinalFisicoRappi, calculatedAmounts.montoFinalSistemaRappi);
    }

    TipoMontoTextoSection.write(`${data.MontoFinalTarjetaVisaSistema !== 0 ? "VISA".padEnd(27, "") : ''}`);
    
    if (data.MontoFinalTarjetaVisaSistema !== 0) {
        writeMontos(TipoMontoNumberSection, simbolo, data.MontoFinalTarjetaVisaFisico, data.MontoFinalTarjetaVisaSistema)
    }
    
    TipoMontoTextoSection.write(`${data.MontoFinalTarjetaMasterCardSistema !== 0 ? "MASTERCARD".padEnd(27, "") : ''}`);

    if (data.MontoFinalTarjetaMasterCardSistema !== 0) {
        writeMontos(TipoMontoNumberSection, simbolo, data.MontoFinalTarjetaMasterCardFisico, data.MontoFinalTarjetaMasterCardSistema)
    }

    TipoMontoTextoSection.write(`${data.MontoFinalTarjetaAmericanSistema !== 0 ? "AMERICAN EXP.".padEnd(27, "") : ''}`);
    
    if (data.MontoFinalTarjetaAmericanSistema !== 0) {
        writeMontos(TipoMontoNumberSection, simbolo, data.MontoFinalTarjetaAmericanFisico, data.MontoFinalTarjetaAmericanSistema)
    }

    TipoMontoTextoSection.write(`${data.MontoFinalOtraTarjetaSistema !== 0 ? "OTROS".padEnd(27, "")  : ''}`);

    if (data.MontoFinalOtraTarjetaSistema !== 0) {
        writeMontos(TipoMontoNumberSection, simbolo, data.MontoFinalOtraTarjetaFisico, data.MontoFinalOtraTarjetaSistema);
    }

    TipoMontoTextoSection.write(`${data.MontoFinalFpay !== 0 ? "FPAY".padEnd(27, "") : ''}`);
    
    if (data.MontoFinalFpay !== 0) {
        writeMontos(TipoMontoNumberSection, simbolo, data.MontoFinalFpay, data.MontoFinalFpay);
    }

    const SumatoriaMontosNumbersStyle = new Style(9, "bold", 1, 'right');
    const SumatoriaMontosNumbersSection = new Section(
        doc,
        4,
        TipoMontoNumberSection.endY + 1,
        SumatoriaMontosNumbersStyle,
        MontosSection.width
    );

    const SumatoriaMontosTextoStyle = new Style(9, "bold", 1, 'left');
    const SumatoriaMontosTextoSection = new Section(
        doc,
        4,
        TipoMontoNumberSection.endY + 1,
        SumatoriaMontosTextoStyle,
        MontosSection.width
    );

    SumatoriaMontosTextoSection.write("SUMATORIA".padEnd(27, ""));
    
    writeMontos(SumatoriaMontosNumbersSection, simbolo, calculatedAmounts.sumatoriaMontosFisico, calculatedAmounts.sumatoriaMontosSistema);

    const TotalMasterStyle = new Style(9, "bold", 1.3, 'center');
    const TotalMasterSection = new Section(
        doc,
        3,
        SumatoriaMontosNumbersSection.endY + 2,
        TotalMasterStyle,
        pageWidth - 6,
        null,
        1,
    );

    TotalMasterSection.write(`TOTAL MASTER`);
    TotalMasterSection.drawBorder(false, true);

    const TotalMasterTextoStyle = new Style(9, "normal", 1, 'left');;
    const TotalMasterTextoSection = new Section(
        doc,
        4,
        TotalMasterSection.endY + 1,
        TotalMasterTextoStyle,
        MontosSection.width
    );
    
    const TotalMasterNumberStyle = new Style(9, "normal", 1, 'right');;
    const TotalMasterNumberSection = new Section(
        doc,
        4,
        TotalMasterSection.endY + 1,
        TotalMasterNumberStyle,
        MontosSection.width
    );

    if (!confEmpresa.ignoreInitialAmount) {
        TotalMasterTextoSection.write(`${data.MontoInicial !== 0 ? "MONTO APERTURA" : ''}`);

        if (data.MontoInicial !== 0) {
            TotalMasterNumberSection.write(`${simbolo + '. ' + (decimalAdjust('floor', +data.MontoInicial, -2)).toFixed(2)}`);
        }
    }

    TotalMasterTextoSection.write(`${calculatedAmounts.totalEfectivoSistema !== 0 ? "TOTAL VENTAS EFECTIVO".padEnd(27, " ") : ''}`);
    
    if (calculatedAmounts.totalEfectivoSistema !== 0) {
        TotalMasterNumberSection.write(`${simbolo + '. ' + (decimalAdjust('floor', +calculatedAmounts.totalVentasEfectivo, -2)).toFixed(2)}`);
    }
    
    TotalMasterTextoSection.write(`${calculatedAmounts.totalPagosDigitalesSistema !== 0 ? "TOTAL PAGOS DIGITALES".padEnd(27, "") : ''}`);
    
    if (calculatedAmounts.totalPagosDigitalesSistema !== 0) {
        TotalMasterNumberSection.write(`${simbolo + '. ' + (decimalAdjust('floor', +calculatedAmounts.totalPagosDigitalesSistema, -2)).toFixed(2)}`);
    }

    TotalMasterTextoSection.write(`${calculatedAmounts.totalTarjetasSistema !== 0 ? "TOTAL PAGO CON TARJETAS".padEnd(27, "") : ''}`);
    
    if (calculatedAmounts.totalTarjetasSistema !== 0) {
        TotalMasterNumberSection.write(`${simbolo + '. ' + (decimalAdjust('floor', +calculatedAmounts.totalTarjetasSistema, -2)).toFixed(2)}`);
    }

    TotalMasterTextoSection.write(`${calculatedAmounts.totalRestoEntradaSalida !== 0 ? "R. ENTRADA Y SALIDA" : ''}`);
    
    if (calculatedAmounts.totalTarjetasSistema !== 0) {
        TotalMasterNumberSection.write(`${simbolo + '. ' + (decimalAdjust('floor', +calculatedAmounts.totalRestoEntradaSalida, -2)).toFixed(2)}`);
    }

    const TotalMasterTotalTextoStyle = new Style(9, "bold", 1, 'left');;
    const TotalMasterTotalTextoSection = new Section(
        doc,
        4,
        TotalMasterNumberSection.endY + 1,
        TotalMasterTotalTextoStyle,
        MontosSection.width
    );
    
    const TotalMasterTotalNumberStyle = new Style(9, "bold", 1, 'right');;
    const TotalMasterTotalNumberSection = new Section(
        doc,
        4,
        TotalMasterNumberSection.endY + 1,
        TotalMasterTotalNumberStyle,
        MontosSection.width
    );

    TotalMasterTotalTextoSection.write(`${calculatedAmounts.totalGeneralSistema !== 0 ? "                                TOTAL".padEnd(27, "") : ''}`);
    
    if (calculatedAmounts.totalGeneralSistema !== 0) {
        TotalMasterTotalNumberSection.write(`${simbolo + '. ' + (decimalAdjust('floor', +calculatedAmounts.totalGeneralSistema, -2)).toFixed(2)}`);
    }

    const DiferenciaTitleStyle = new Style(10, "bold", 1, 'center');
    const DiferenciaTitleSection = new Section(
        doc,
        3,
        TotalMasterTotalTextoSection.endY + 2,
        DiferenciaTitleStyle,
        pageWidth - 6,
        null,
        1
    );

    DiferenciaTitleSection.write("DIFERENCIA DE MONTOS");
    DiferenciaTitleSection.drawBorder(false, true);

    const DiferenciaTextoStyle = new Style(9, "normal", 1, "left");
    const DiferenciaTextoSection = new Section(
        doc,
        4,
        DiferenciaTitleSection.endY + 2,
        DiferenciaTextoStyle,
        DiferenciaTitleSection.width
    );
    const DiferenciaNumberStyle = new Style(9, "normal", 1, "right");
    const DiferenciaNumberSection = new Section(
        doc,
        4,
        DiferenciaTitleSection.endY + 2,
        DiferenciaNumberStyle,
        DiferenciaTitleSection.width
    );

    const diferenciaEfectivo = data.MontoFinalEfectivoFisico - data.MontoFinalEfectivoSistema;
    DiferenciaTextoSection.write(`${diferenciaEfectivo !== 0 ? "D. EFECTIVO" : ''}`);
    DiferenciaNumberSection.write(`${diferenciaEfectivo !== 0 ? infoPdf[0][0][0].Simbolo.padEnd(2, " ") + '. ' + decimalAdjust('floor', diferenciaEfectivo, -2).toFixed(2) : ''}`);

    const diferenciaYape = calculatedAmounts.montoFinalFisicoYape - calculatedAmounts.montoFinalSistemaYape;
    DiferenciaTextoSection.write(`${diferenciaYape !== 0 ? "D. YAPE" : ''}`);
    DiferenciaNumberSection.write(`${diferenciaYape !== 0 ? infoPdf[0][0][0].Simbolo.padEnd(2, " ") + '. ' + decimalAdjust('floor', diferenciaYape, -2).toFixed(2) : ''}`);

    const diferenciaPlin = calculatedAmounts.montoFinalFisicoPlin - calculatedAmounts.montoFinalSistemaPlin;
    DiferenciaTextoSection.write(`${diferenciaPlin !== 0 ? "D. PLIN" : ''}`);
    DiferenciaNumberSection.write(`${diferenciaPlin !== 0 ? infoPdf[0][0][0].Simbolo.padEnd(2, " ") + '. ' + decimalAdjust('floor', diferenciaPlin, -2).toFixed(2) : ''}`);

    const diferenciaTunki = calculatedAmounts.montoFinalFisicoTunki - calculatedAmounts.montoFinalSistemaTunki;
    DiferenciaTextoSection.write(`${diferenciaTunki !== 0 ? "D. TUNKI" : ''}`);
    DiferenciaNumberSection.write(`${diferenciaTunki !== 0 ? infoPdf[0][0][0].Simbolo.padEnd(2, " ") + '. ' + decimalAdjust('floor', diferenciaTunki, -2).toFixed(2) : ''}`);

    const diferenciaRappi = calculatedAmounts.montoFinalFisicoRappi - calculatedAmounts.montoFinalSistemaRappi;
    DiferenciaTextoSection.write(`${diferenciaRappi !== 0 ? "D. RAPPI" : ''}`);
    DiferenciaNumberSection.write(`${diferenciaRappi !== 0 ? infoPdf[0][0][0].Simbolo.padEnd(2, " ") + '. ' + decimalAdjust('floor', diferenciaRappi, -2).toFixed(2) : ''}`);

    const diferenciaVisa = data.MontoFinalTarjetaVisaFisico - data.MontoFinalTarjetaVisaSistema;
    DiferenciaTextoSection.write(`${diferenciaVisa !== 0 ? "D. VISA" : ''}`);
    DiferenciaNumberSection.write(`${diferenciaVisa !== 0 ? infoPdf[0][0][0].Simbolo.padEnd(2, " ") + '. ' + decimalAdjust('floor', diferenciaVisa, -2).toFixed(2) : ''}`);

    const diferenciaMastercard = data.MontoFinalTarjetaMasterCardFisico - data.MontoFinalTarjetaMasterCardSistema;
    DiferenciaTextoSection.write(`${diferenciaMastercard !== 0 ? "D. MASTERCARD" : ''}`);
    DiferenciaNumberSection.write(`${diferenciaMastercard !== 0 ? infoPdf[0][0][0].Simbolo.padEnd(2, " ") + '. ' + decimalAdjust('floor', diferenciaMastercard, -2).toFixed(2) : ''}`);

    const diferenciaAmerican = data.MontoFinalTarjetaAmericanFisico - data.MontoFinalTarjetaAmericanSistema;
    DiferenciaTextoSection.write(`${diferenciaAmerican !== 0 ? "D. AM. EXPRESS" : ''}`);
    DiferenciaNumberSection.write(`${diferenciaAmerican !== 0 ? infoPdf[0][0][0].Simbolo.padEnd(2, " ") + '. ' + decimalAdjust('floor', diferenciaAmerican, -2).toFixed(2) : ''}`);

    const diferenciaTotal = diferenciaEfectivo + diferenciaYape + diferenciaPlin + diferenciaTunki + diferenciaRappi + diferenciaVisa + diferenciaMastercard + diferenciaAmerican;

    const DiferenciaTotalTextoStyle = new Style(9, "bold", 1, "left");
    const DiferenciaTotalTextoSection = new Section(
        doc,
        4,
        DiferenciaNumberSection.endY + 1,
        DiferenciaTotalTextoStyle,
        DiferenciaTitleSection.width
    );
    const DiferenciaTotalNumberStyle = new Style(9, "bold", 1, "right");
    const DiferenciaTotalNumberSection = new Section(
        doc,
        4,
        DiferenciaNumberSection.endY + 1,
        DiferenciaTotalNumberStyle,
        DiferenciaTitleSection.width
    );

    DiferenciaTotalTextoSection.write(`${diferenciaTotal !== 0 ? "TOTAL DIFERENCIA" : ''}`);
    DiferenciaTotalNumberSection.write(`${diferenciaTotal !== 0 ? infoPdf[0][0][0].Simbolo.padEnd(2, " ") + '. ' + decimalAdjust('floor', diferenciaTotal, -2).toFixed(2) : ''}`);

    const RedondeoTitleStyle = new Style(10, "bold", 1, 'center');
    const RedondeoTitleSection = new Section(
        doc,
        3,
        DiferenciaTotalTextoSection.endY + 2,
        RedondeoTitleStyle,
        pageWidth - 6,
        null,
        1
    );

    RedondeoTitleSection.write("REDONDEOS DEL TURNO");
    RedondeoTitleSection.drawBorder(false, true);

    const RedondeoTextoStyle = new Style(9, "normal", 1, "left");
    const RedondeoTextoSection = new Section(
        doc,
        4,
        RedondeoTitleSection.endY + 2,
        RedondeoTextoStyle,
        RedondeoTitleSection.width
    );

    RedondeoTextoSection.write(`REDONDEO COMPROBANTE ACEPTADOS: ${data.redondeoRVAceptados}`);
    RedondeoTextoSection.write(`REDONDEO COMPROBANTE ANULADOS:  ${data.redondeoRVAnulados}`);

    const CorrelativosTitleStyle = new Style(10, "bold", 1, 'center');
    const CorrelativosTitleSection = new Section(
        doc,
        3,
        RedondeoTextoSection.endY + 2,
        CorrelativosTitleStyle,
        pageWidth - 6,
        null,
        1
    );

    CorrelativosTitleSection.write("COMPROBANTES EMITIDOS");
    CorrelativosTitleSection.drawBorder(false, true);

    const CorrelativosStyle = new Style(9, "normal", 1);
    const CorrelativosSection = new Section(
        doc,
        4,
        CorrelativosTitleSection.endY + 4,
        CorrelativosStyle,
        CorrelativosTitleSection.width
    );

    if (infoPdf[0][1].length !== 0) {
        infoPdf[0][1].map((obj) => {
            CorrelativosSection.write(`${obj.Descripcion}`);
            CorrelativosSection.write(`                     ${obj.Serie} ${obj.Primero} - ${obj.Ultimo} (${+obj.Ultimo - +obj.Primero + 1})`);
        })
    }
    if (infoPdf[0][2].length !== 0) {
        infoPdf[0][2].map((obj) => {
            CorrelativosSection.write(`${obj.Descripcion}`);
            CorrelativosSection.write(`                     ${obj.Serie} ${obj.Primero} - ${obj.Ultimo} (${+obj.Ultimo - +obj.Primero + 1})`);
        })
    }


    const comprobantesAnuladosTitleStyle = new Style(10, "bold", 1, 'center');
    const comprobantesAnuladosTitleSection = new Section(
        doc,
        3,
        CorrelativosSection.endY + 4,
        comprobantesAnuladosTitleStyle,
        pageWidth - 6,
        null,
        1
    );

    comprobantesAnuladosTitleSection.write("INFO. COMPROBANTES ANULADOS");
    comprobantesAnuladosTitleSection.drawBorder(false, true);

    const compAnuladosStyle = new Style(9, "normal", 1);
    const compAnuladosSection = new Section(
        doc,
        4,
        comprobantesAnuladosTitleSection.endY + 4,
        compAnuladosStyle,
        comprobantesAnuladosTitleSection.width
    );

    const movsCajasAnulados = data.movCajas.filter(m => m.Tipo === TIPO_MOV_CAJA.SALIDA && m.Observacion.includes("Anulación del comprobante"));
    movsCajasAnulados.forEach(m => compAnuladosSection.write(`${m.Observacion}: S/. ${m.Monto} `))

    let sum = 0;
    movsCajasAnulados.forEach(m => sum += m.Monto);
    compAnuladosSection.write(`Monto Total de comprobantes anulados: S/. ${sum} `)


    // mov venta section
    const movVentaStyle = new Style(10, "bold", 1.3, 'center');
    const movVentaSection = new Section(
        doc,
        3,
        compAnuladosSection.endY + 3,
        movVentaStyle,
        pageWidth - 6,
        null,
        1
    );

    movVentaSection.write(`      INFORMACIÓN  DE MOVIMIENTOS`);
    movVentaSection.drawBorder(false, true);


    const movVentaTextStyle = new Style(9, "normal", 1, 'left');
    const movVentaTextSection = new Section(
        doc,
        4,
        movVentaSection.endY + 4,
        movVentaTextStyle,
        movVentaSection.width
    );

    data.movCajas.forEach(m => movVentaTextSection.write(`${m.Tipo} - ${moment(m.FechaHora).format("DD/MM/YYYY HH:mm")} - ${m.Observacion && m.Observacion.length ? m.Observacion : "No se ingresó observación"}`))

    doc.autoPrint();
    const bloburl = doc.output("bloburl");
    printBlob(bloburl)

}


async function TraerInfoPDF(idTurno) {
    const url = idTurno ? `/api/cajas/cierreTurno/InfoPDFTL/${idTurno}` : `/api/cajas/cierreTurno/InfoPDF/`
    try {
        const response = await fetch(url);
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function numeroALetras(total) {
    try {
        const response = await fetch(`/api/cajas/numero-letras?total=${total}`);
        let data = await response.json();
        return data.desc;
    } catch (error) {
        return '';
    }
}

